<template>
  <div id="app">
    <img 
      alt="Vue logo"
      src="./assets/okey_logo.png"
      width="125"
      height="125"
    >
    <ForgotPassword/>
  </div>
</template>

<script>
import ForgotPassword from './components/ForgotPass.vue'

export default {
  name: 'App',
  components: {
    ForgotPassword
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body{
 background-color: black;
}
</style>
