import Vue from 'vue'
import App from './App'
import VueAxios from 'vue-axios'
import axios from 'axios'
import ForgotPass from '@/components/ForgotPass'
import VueInputAutowidth from 'vue-input-autowidth'
import Toastr from 'vue-toastr'

Vue.use(VueInputAutowidth)
Vue.use(
  Toastr,
  {
    defaultTimeout: 5000,
    defaultType: 'error',
    defaultCloseOnHover: false
  }
)

const routes = {
  '/': ForgotPass,
  '/forgot-password': ForgotPass
}
Vue.use(VueAxios, axios)
Vue.config.productionTip = false


new Vue({
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute]
    }
  },
  render: h => h(App)
}).$mount('#app')
